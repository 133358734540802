import { getGameById, getGamesInfo } from 'src/core/api/GamesStore';

class gamesService {
  getGamesInfo = async () => {
    try {
      return getGamesInfo();
    } catch (error) {
      return error;
    }
  };
  getGameById = async gameId => {
    try {
      return getGameById(gameId);
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}

export default new gamesService();
