import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import creditsService from 'src/services/creditsService';

export const GET_CREDIT_PACKAGES = '@credits/get-credit-packages';
export const PURCHASE_CREDITS = '@credits/purchase-credits';
export const ADD_CREDITS = '@credits/add-credits';
export const GET_USER_CREDITS = '@credits/get-user-credits';
export const GET_USER_PURCHASE_HISTORY = '@credits/get-user-purchase-history';
export const GET_EVENT_CONSUME_REPORT = '@credits/get-event-consume-report';

export const getCreditPackages = createAsyncThunk(
  GET_CREDIT_PACKAGES,
  async () => {
    return await creditsService.getCreditPackages();
  }
);

export const purchaseCredits = createAsyncThunk(
  PURCHASE_CREDITS,
  async creditsPackageId => {
    return await creditsService.purchaseCredits(creditsPackageId);
  }
);

export const addCredits = createAction(ADD_CREDITS);

export const getUserCredits = createAsyncThunk(
  GET_USER_CREDITS,
  async (eventId = null) => {
    return await creditsService.getUserCredits(eventId);
  }
);

export const getUserPurchaseHistory = createAsyncThunk(
  GET_USER_PURCHASE_HISTORY,
  async () => {
    return await creditsService.getUserPurchaseHistory();
  }
);

export const getEventConsumeReport = createAsyncThunk(
  GET_EVENT_CONSUME_REPORT,
  async eventId => {
    return await creditsService.getEventConsumeReport(eventId);
  }
);
