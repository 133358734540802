import { functions, store } from 'src/core/api/firebase';

export async function getCreditPackages() {
  try {
    const creditPackages = await store
      .collection('/credits/creditsPackages/packages')
      .get();
    return creditPackages.docs
      .map(doc => ({
        ...doc.data(),
        id: doc.id
      }))
      .sort((a, b) => a.price - b.price);
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getUserCredits(userId) {
  try {
    const getCreditsAmount = functions.httpsCallable('credits-getCredits');

    const response = await getCreditsAmount(userId);

    if (response.data.success) {
      return response.data.creditsAmount;
    }
    return 0;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function purchaseCredits(
  creditPackageId,
  userId,
  connectedAccountId
) {
  const purchaseCredits = functions.httpsCallable('credits-creditsPayment');

  const response = await purchaseCredits({
    creditPackageId,
    userId,
    connectedAccountId
  });

  return response.data;
}

export async function getCreditsPurchaseHistory(userId) {
  const purchaseHistory = await store
    .collection('/credits/creditsHistory/history')
    .where('userId', '==', userId)
    .where('type', '==', 'buy')
    .get();

  return purchaseHistory.docs.map(doc => ({
    ...doc.data(),
    id: doc.id
  }));
}

export async function getEventConsumeReport(eventId) {
  const consumeReport = await store
    .collection('/credits/creditsHistory/history')
    .where('eventId', '==', eventId)
    .get();

  const doc = consumeReport.docs[0];
  if (!doc.exists) {
    throw new Error(
      `No consume report found for event with eventId=${eventId}`
    );
  }
  return { ...doc.data(), id: doc.id };
}
