import firebase from 'firebase';
import { store } from './firebase';

export async function getGamesInfo() {
  const games = await store.collection('/apps').get();
  return games.docs.map(doc => ({
    ...doc.data(),
    id: doc.id
  }));
}
export async function getGameById(gameId) {
  const game = await store.doc(`/apps/${gameId}`).get();
  const gameContent = await store
    .doc(`/apps/${gameId}/content/default-content`)
    .get();

  const rightSideMenuDocs = await store
    .collection(`/apps/${gameId}/content/default-content/rightSideMenu`)
    .get();

  const rightSideMenu = rightSideMenuDocs.docs
    .map(doc => ({
      ...doc.data(),
      id: doc.id
    }))
    .sort((a, b) => a.order - b.order);

  const textsDocs = await store
    .collection(`/apps/${gameId}/content/default-content/texts`)
    .get();

  const texts = textsDocs.docs
    .map(doc => ({
      ...doc.data(),
      id: doc.id
    }))
    .sort((a, b) => a.order - b.order);

  return {
    id: game.id,
    ...gameContent.data(),
    ...game.data(),
    rightSideMenu,
    texts
  };
}
