import { createReducer } from '@reduxjs/toolkit';
import { getGamesInfo, getGameById } from 'src/actions/gamesActions';

const initialState = {
  games: [],
  game: null,
  loading: false,
  loaded: false,
  error: null
};

const integrationsSectionReducer = createReducer(initialState, {
  [getGamesInfo.pending]: state => {
    state.loading = true;
  },
  [getGamesInfo.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    state.games = payload;
  },
  [getGamesInfo.rejected]: (state, { error }) => {
    state.error = error;
    state.loading = false;
  },
  [getGameById.pending]: state => {
    state.loading = true;
  },
  [getGameById.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    state.game = payload;
  },
  [getGameById.rejected]: (state, { error }) => {
    state.error = error;
    state.loading = false;
  }
});

export default integrationsSectionReducer;
