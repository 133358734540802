import { createAsyncThunk } from '@reduxjs/toolkit';
import gamesService from 'src/services/gamesService';

export const GET_GAMES_INFO = '@games/get-games-info';
export const GET_GAME_BY_ID = '@games/get-game-by-id';

export const getGamesInfo = createAsyncThunk(GET_GAMES_INFO, async () => {
  return gamesService.getGamesInfo();
});
export const getGameById = createAsyncThunk(GET_GAME_BY_ID, async gameId => {
  return gamesService.getGameById(gameId);
});
