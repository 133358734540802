import { createReducer } from '@reduxjs/toolkit';
import {
  getCreditPackages,
  getUserCredits,
  purchaseCredits,
  addCredits,
  getUserPurchaseHistory,
  getEventConsumeReport
} from 'src/actions/creditsActions';

const initialState = {
  creditsPackages: null,
  loading: false,
  loaded: false,
  error: null,
  creditsAmount: 0,
  loadingPurchase: false,
  creditsHistory: [],
  consumeReport: {
    creditsConsumed: null,
    participantsList: [],
    totalParticipants: null
  }
};
const creditsReducer = createReducer(initialState, {
  [getCreditPackages.pending]: (state, _) => {
    state.loading = true;
  },
  [getCreditPackages.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    state.creditsPackages = payload;
  },
  [getCreditPackages.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getUserCredits.pending]: state => {
    state.loading = true;
  },
  [getUserCredits.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    state.creditsAmount = payload;
  },
  [getUserCredits.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [purchaseCredits.pending]: state => {
    state.loading = true;
    state.loadingPurchase = true;
  },
  [purchaseCredits.fulfilled]: state => {
    state.loading = false;
    state.loaded = true;
    state.loadingPurchase = true;
  },
  [purchaseCredits.rejected]: (state, { error }) => {
    state.loading = false;
    state.loadingPurchase = false;
    state.error = error;
  },
  [addCredits.type]: (state, { payload }) => {
    state.creditsAmount = payload;
    state.loadingPurchase = false;
  },
  [getUserPurchaseHistory.pending]: state => {
    state.loading = true;
    state.loadingPurchase = true;
  },
  [getUserPurchaseHistory.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    state.loadingPurchase = false;
    state.creditsHistory = payload;
  },
  [getUserPurchaseHistory.rejected]: (state, { error }) => {
    state.loading = false;
    state.loadingPurchase = false;
    state.error = error;
  },
  [getEventConsumeReport.pending]: state => {
    state.loadingConsumeReport = true;
  },
  [getEventConsumeReport.rejected]: (state, { error }) => {
    state.loadingConsumeReport = false;
    state.error = error;
  },
  [getEventConsumeReport.fulfilled]: (state, { payload }) => {
    state.loadingConsumeReport = false;
    state.loadedConsumeReport = true;
    state.consumeReport = payload;
  }
});

export default creditsReducer;
