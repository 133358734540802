import firebase from 'firebase';
import { NETWORKING_AREA } from 'src/constants/video-types';
import { functions, storage, store } from './firebase';

const EVENTS_COLLECTION = 'events';
const SESSIONS_COLLECTION = 'sessions';
const NETWORKS_COLLECTION = 'networking-tables';

export async function createNetworks(networks, userId, eventId) {
  const requestData = { networkingTables: networks, userId, eventId };
  const createNetworkingTables = functions.httpsCallable(
    'networkingTables-createNetworkingTables'
  );
  const response = await createNetworkingTables(requestData);

  if (response.data.success) {
    return await getEventNetworks(eventId);
  }

  throw new Error(response.data.error);
}

export async function getEventNetworks(eventId) {
  const networkDocs = await store
    .collection(EVENTS_COLLECTION)
    .doc(eventId)
    .collection(NETWORKS_COLLECTION)
    .get();

  return networkDocs.docs.map(doc => ({
    ...doc.data(),
    id: doc.id,
    createdDate: doc.data().createdDate.toDate()
  }));
}

export async function deleteNetworks(networkIds, eventId) {
  const deleteNetworkingTables = functions.httpsCallable(
    'networkingTables-deleteNetworkingTables'
  );

  const response = await deleteNetworkingTables({ networkIds, eventId });

  if (response.data.success) {
    return response.data.networkIds;
  }
  throw new Error(response.data.error);
}

export async function updateNetwork(network, eventId) {
  const requestData = { networkingTable: network, eventId };
  const updateNetworkingTable = functions.httpsCallable(
    'networkingTables-updateNetworkingTable'
  );

  const response = await updateNetworkingTable(requestData);

  if (response.data.success) {
    let updatedNetwork = response.data.updatedNetwork;

    let downloadUrl = null;

    if (network.logoRaw) {
      const networkPath = `${EVENTS_COLLECTION}/${eventId}/${NETWORKS_COLLECTION}/${network.id}`;
      const uploadTask = await storage.ref(networkPath).put(network.logoRaw);
      try {
        downloadUrl = await uploadTask.ref.getDownloadURL();
        const networkDoc = store.doc(networkPath);

        await networkDoc.set({ logoUrl: downloadUrl }, { merge: true });
      } catch (error) {
        console.error(
          '[NETWORKING-TABLE] An error has occured while uploading networking table picture.',
          `error-message: ${error.message}`
        );
      }
      updatedNetwork = {
        ...updatedNetwork,
        logoUrl: downloadUrl
      };
    }

    return updatedNetwork;
  }
  throw new Error(response.data.error.message);
}

export async function updateNetworksSort(changesList, eventId) {
  const batch = store.batch();

  changesList.forEach(changes => {
    const networkRef = store.doc(
      `${EVENTS_COLLECTION}/${eventId}/${NETWORKS_COLLECTION}/${changes.id}`
    );

    batch.update(networkRef, {
      id: changes.id,
      sort: changes.sort,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });
  });

  await batch.commit();

  return changesList;
}

export async function getNetworkById(eventId, networkId) {
  const networkDoc = store
    .collection(`${EVENTS_COLLECTION}/${eventId}/${NETWORKS_COLLECTION}`)
    .doc(networkId);
  const network = await networkDoc.get();
  if (!network.exists) return null;
  return { id: network.id, ...network.data() };
}
