/* eslint-disable react/no-array-index-key */
import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthGuard from 'src/components/AuthGuard';
import EventsGuard from 'src/components/EventsGuard';
import GuestGuard from 'src/components/GuestGuard';
import DashboardLayout from 'src/layouts/DashboardLayout';
import EventLayout from 'src/layouts/EventLayout';
import ReportsLayout from 'src/layouts/ReportsLayout';
import * as routes from 'src/routeConstants';
import LandingPageLayout from './layouts/LandingPageLayout';
import TicketsLayout from './layouts/TicketsLayout';
import SponsorsLayout from './layouts/SponsorsLayout';
import BillingLayout from './layouts/BillingLayout';
import AttendeesLayout from './layouts/AttendeesLayout';

const routesConfig = [
  {
    exact: true,
    path: routes.HOME,
    guard: AuthGuard,
    layout: DashboardLayout,
    component: () => <Redirect to={routes.EVENTS} />
  },
  {
    exact: true,
    path: routes.TERMS_CONDITIONS,
    component: lazy(() => import('src/views/auth/TermsConditionsView'))
  },
  {
    exact: true,
    path: routes.PRIVACY_POLICY,
    component: lazy(() => import('src/views/auth/PrivacyPolicyView'))
  },
  {
    path: routes.BILLING,
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        path: routes.BILLING,
        layout: BillingLayout,
        routes: [
          {
            exact: true,
            path: `${routes.BILLING}`,
            component: lazy(() => import('src/views/billing/BillingView'))
          },
          {
            exact: true,
            path: `${routes.BILLING}${routes.PACKAGES}`,
            component: lazy(() => import('src/views/billing/SelectPackageView'))
          },
          {
            exact: true,
            path: `${routes.BILLING}${routes.INVOICES}`,
            component: lazy(() => import('src/views/billing/InvoicesView'))
          },
          {
            exact: true,
            path: `${routes.BILLING}${routes.CREDITS}`,
            component: lazy(() => import('src/views/billing/CreditsView'))
          },
          {
            exact: true,
            path: `${routes.BILLING}${routes.PACKAGES}${routes.CONFIRMATION}`,
            component: lazy(() => import('src/views/billing/ThankYouView'))
          },
          {
            exact: true,
            path: `${routes.BILLING}${routes.PACKAGES}${routes.CHECKOUT}`,
            component: lazy(() => import('src/views/billing/CheckoutView'))
          },
          {
            exact: true,
            path: `${routes.BILLING}${routes.BILLING_INFORMATION}`,
            component: lazy(() =>
              import('src/views/billing/BillingInformationView')
            )
          },
          {
            exact: true,
            path: `${routes.BILLING}/approve`,
            component: lazy(() =>
              import('src/views/billing/StripeApproveConnectView')
            )
          }
        ]
      }
    ]
  },
  {
    path: routes.ATTENDEES,
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        path: routes.ATTENDEES,
        layout: AttendeesLayout,
        routes: [
          {
            exact: true,
            path: routes.ATTENDEES,
            component: lazy(() => import('src/views/attendees/AttendeesView'))
          },
          {
            exact: true,
            path: `${routes.ATTENDEES}${routes.SCAN_QR}`,
            component: lazy(() => import('src/views/scanQR/ScanQRView'))
          }
        ]
      }
    ]
  },
  {
    path: routes.TEAM,
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: routes.TEAM,
        component: lazy(() => import('src/views/team/TeamListView'))
      },
      {
        exact: true,
        path: `${routes.TEAM}${routes.CREATE}`,
        component: lazy(() => import('src/views/team/TeamCreateView'))
      },
      {
        exact: true,
        path: `${routes.TEAM}${routes.REQUESTS}`,
        component: lazy(() => import('src/views/team/TeamRequestsListView'))
      }
    ]
  },
  {
    path: routes.EVENTS,
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: routes.EVENTS,
        component: lazy(() => import('src/views/event/EventListView'))
      },
      {
        exact: true,
        path: `${routes.EVENTS}${routes.CREATE}`,
        component: lazy(() => import('src/views/event/EventCreateView'))
      },
      {
        exact: true,
        path: `${routes.EVENTS}${routes.SHARED}`,
        component: lazy(() => import('src/views/event/SharedEventListView'))
      }
    ]
  },
  {
    path: routes.EVENT,
    guard: EventsGuard,
    layout: EventLayout,
    routes: [
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}`,
        component: lazy(() => import('src/views/event/EventOverview'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.KEY_DETAILS}`,
        component: lazy(() => import('src/views/event/KeyDetailsView'))
      },
      {
        path: `${routes.EVENT}${routes.ID}${routes.REPORTS}`,
        layout: ReportsLayout,
        routes: [
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.REPORTS}`,
            component: lazy(() => import('src/views/event/ReportsView'))
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.REPORTS}/${routes.REPORTS_SESSIONS}`,
            component: lazy(() =>
              import('src/views/event/ReportsView/SessionsReportsView')
            )
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.REPORTS}/${routes.REPORTS_NETWORKING}`,
            component: lazy(() =>
              import('src/views/event/ReportsView/NetworkingReportsView')
            )
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.REPORTS}/${routes.REPORTS_EXPO}`,
            component: lazy(() =>
              import('src/views/event/ReportsView/ExpoReportsView')
            )
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.REPORTS}${routes.REPORT_DOWNLOADS}`,
            component: lazy(() =>
              import('src/views/event/ReportsView/DownloadReportsView')
            )
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.REPORTS}${routes.REPORTS_GAME}`,
            component: lazy(() =>
              import('src/views/event/ReportsView/GameReportsView')
            )
          }
        ]
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.SPEAKERS}`,
        component: lazy(() => import('src/views/event/SpeakerListView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.SPEAKERS}${routes.CREATE}`,
        component: lazy(() => import('src/views/event/SpeakerCreateView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.SPEAKERS}${routes.ID}${routes.EDIT}`,
        component: lazy(() => import('src/views/event/SpeakerEditView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.STAGES}`,
        component: lazy(() => import('src/views/event/StagesListView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.STAGES}${routes.CREATE}`,
        component: lazy(() => import('src/views/event/StageCreateView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.STAGES}${routes.ID}${routes.EDIT}`,
        component: lazy(() => import('src/views/event/StageEditView'))
      },
      {
        path: `${routes.EVENT}${routes.ID}${routes.TICKETS}`,
        layout: TicketsLayout,
        routes: [
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.TICKETS}`,
            component: lazy(() => import('src/views/event/TicketListView'))
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.TICKETS}${routes.CREATE}`,
            component: lazy(() => import('src/views/event/TicketCreateView'))
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.TICKETS}${routes.ID}${routes.EDIT}`,
            component: lazy(() => import('src/views/event/TicketEditView'))
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.TICKETS}${routes.PROMO_CODES}`,
            component: lazy(() => import('src/views/event/PromoCodeListView'))
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.TICKETS}${routes.PROMO_CODES}${routes.CREATE}`,
            component: lazy(() => import('src/views/event/PromoCodeCreateView'))
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.TICKETS}${routes.PROMO_CODES}${routes.ID}${routes.EDIT}`,
            component: lazy(() => import('src/views/event/PromoCodeEditView'))
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.TICKETS}${routes.ADD_ONS}`,
            component: lazy(() =>
              import('src/views/event/TicketAddonsListView')
            )
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.TICKETS}${routes.ADD_ONS}${routes.CREATE}`,
            component: lazy(() =>
              import('src/views/event/TicketAddonsCreateView')
            )
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.TICKETS}${routes.ADD_ONS}${routes.ID}${routes.EDIT}`,
            component: lazy(() =>
              import('src/views/event/TicketAddonsEditView')
            )
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.TICKETS}${routes.CUSTOM_REGISTRATION_FORMS}${routes.NEW_CUSTOM_REGISTRATION_FORMS}`,
            component: lazy(() =>
              import(
                'src/views/event/CustomRegistrationFormsView/NewCustomRegistrationFormView'
              )
            )
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.TICKETS}${routes.CUSTOM_REGISTRATION_FORMS}${routes.ID}`,
            component: lazy(() =>
              import('src/views/event/CustomRegistrationFormView')
            )
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.TICKETS}${routes.CUSTOM_REGISTRATION_FORMS}`,
            component: lazy(() =>
              import('src/views/event/CustomRegistrationFormsView')
            )
          }
        ]
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.SCHEDULE}`,
        component: lazy(() => import('src/views/event/AgendaView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.SCHEDULE}${routes.CREATE}`,
        component: lazy(() => import('src/views/event/SessionCreateView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.SCHEDULE}${routes.ID}${routes.EDIT}`,
        component: lazy(() => import('src/views/event/SessionEditView'))
      },
      {
        path: `${routes.EVENT}${routes.ID}${routes.SPONSORS}`,
        layout: SponsorsLayout,
        routes: [
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.SPONSORS}`,
            component: lazy(() => import('src/views/event/SponsorListView'))
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.SPONSORS}${routes.CREATE}`,
            component: lazy(() => import('src/views/event/SponsorCreateView'))
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.SPONSORS}${routes.ID}${routes.EDIT}`,
            component: lazy(() => import('src/views/event/SponsorEditView'))
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.SPONSORS}${routes.CATEGORY}`,
            component: lazy(() =>
              import('src/views/event/SponsorCategoryListView')
            )
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.SPONSORS}${routes.CATEGORY}${routes.CREATE}`,
            component: lazy(() =>
              import('src/views/event/SponsorCategoryCreateView')
            )
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.SPONSORS}${routes.CATEGORY}${routes.ID}${routes.EDIT}`,
            component: lazy(() =>
              import('src/views/event/SponsorCategoryEditView')
            )
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.SPONSORS}${routes.BOOTH}`,
            component: lazy(() => import('src/views/event/BoothListView'))
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.SPONSORS}${routes.BOOTH}${routes.CREATE}`,
            component: lazy(() => import('src/views/event/BoothCreateView'))
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.SPONSORS}${routes.BOOTH}${routes.ID}${routes.EDIT}`,
            component: lazy(() => import('src/views/event/BoothEditView'))
          }
        ]
      },
      {
        path: `${routes.EVENT}${routes.ID}${routes.NETWORKING}`,
        routes: [
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.NETWORKING}`,
            component: lazy(() => import('src/views/event/NetworkListView'))
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.NETWORKING}${routes.CREATE}`,
            component: lazy(() => import('src/views/event/NetworkCreateView'))
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.NETWORKING}${routes.ID}${routes.EDIT}`,
            component: lazy(() => import('src/views/event/NetworkEditView'))
          }
        ]
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.THEME}`,
        component: lazy(() => import('src/views/event/ThemeView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.THEME}${routes.COLORS_AND_FONTS}`,
        component: lazy(() => import('src/views/event/ColorsAndFontsView'))
      },
      {
        path: `${routes.EVENT}${routes.ID}${routes.THEME}${routes.LANDING_PAGE}`,
        layout: LandingPageLayout,
        routes: [
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.THEME}${routes.LANDING_PAGE}`,
            component: lazy(() =>
              import('src/views/event/LandingPageCustomizeView')
            )
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.THEME}${routes.LANDING_PAGE}${routes.SECTION}`,
            component: lazy(() =>
              import('src/views/event/LandingPageSectionView')
            )
          }
        ]
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.THEME}${routes.TRANSLATIONS}`,
        component: lazy(() => import('src/views/event/TranslationsDetailView'))
      },
      {
        path: `${routes.EVENT}${routes.ID}${routes.THEME}${routes.LEGAL_INFORMATION}`,
        routes: [
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.THEME}${routes.LEGAL_INFORMATION}`,
            component: lazy(() =>
              import('src/views/event/LegalInformationListView')
            )
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.THEME}${routes.LEGAL_INFORMATION}${routes.SECTION}`,
            component: lazy(() =>
              import('src/views/event/LegalInformationSectionView')
            )
          }
        ]
      },
      {
        path: `${routes.EVENT}${routes.ID}${routes.THEME}${routes.EMAIL_TEMPLATES}`,
        routes: [
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.THEME}${routes.EMAIL_TEMPLATES}`,
            component: lazy(() =>
              import('src/views/event/EmailTemplatesListView')
            )
          },
          {
            exact: true,
            path: `${routes.EVENT}${routes.ID}${routes.THEME}${routes.EMAIL_TEMPLATES}${routes.ID}`,
            component: lazy(() =>
              import('src/views/event/EmailTemplateEditView')
            )
          }
        ]
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.THEME}${routes.INTEGRATIONS}`,
        component: lazy(() => import('src/views/event/IntegrationsView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.THEME}${routes.INTEGRATIONS}${routes.TRACKING_CODES}`,
        component: lazy(() =>
          import('src/views/event/IntegrationsCustomizeView')
        )
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.APP_STORE}`,
        component: lazy(() => import('src/views/event/AppStoreView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.APP_STORE}${routes.ID}`,
        component: lazy(() => import('src/views/event/GameView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.DOMAIN}`,
        component: lazy(() => import('src/views/event/DomainListView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.DOMAIN}${routes.CREATE}`,
        component: lazy(() => import('src/views/event/DomainCreateView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.DOMAIN}${routes.ATTACH}`,
        component: lazy(() => import('src/views/event/DomainAttachView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.DOMAIN}${routes.ID}${routes.EDIT}`,
        component: lazy(() => import('src/views/event/DomainEditView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.VIDEO}`,
        component: lazy(() => import('src/views/event/VideoListView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.VIDEO}${routes.CREATE}`,
        component: lazy(() => import('src/views/event/VideoCreateView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.VIDEO}${routes.VIDEO_ID}${routes.EDIT}`,
        component: lazy(() => import('src/views/event/VideoEditView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.TESTIMONIALS}${routes.CREATE}`,
        component: lazy(() => import('src/views/event/TestimonialCreateView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.TESTIMONIALS}`,
        component: lazy(() => import('src/views/event/TestimonialListView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.TESTIMONIALS}${routes.ID}${routes.EDIT}`,
        component: lazy(() => import('src/views/event/TestimonialEditView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.CHECKLIST}`,
        component: lazy(() => import('src/views/event/ChecklistView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.BENEFITS}${routes.CREATE}`,
        component: lazy(() => import('src/views/event/BenefitCreateView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.BENEFITS}`,
        component: lazy(() => import('src/views/event/BenefitListView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.BENEFITS}${routes.ID}${routes.EDIT}`,
        component: lazy(() => import('src/views/event/BenefitEditView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.CUSTOM_SECTIONS}${routes.CREATE}`,
        component: lazy(() => import('src/views/event/CustomSectionCreateView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.CUSTOM_SECTIONS}${routes.ID}${routes.EDIT}`,
        component: lazy(() => import('src/views/event/CustomSectionEditView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.SETTINGS}`,
        component: lazy(() => import('src/views/event/SettingsView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.SETTINGS}${routes.REGISTRATION_FORM}`,
        component: lazy(() =>
          import('src/views/event/CustomRegistrationFormView')
        )
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.SETTINGS}${routes.LIVE_VIDEO_SETTINGS_FORM}`,
        component: lazy(() =>
          import('src/views/event/LiveVideoSettingsFormView')
        )
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.SETTINGS}${routes.CHAT_SETTINGS_FORM}`,
        component: lazy(() => import('src/views/event/ChatSettingsFormView'))
      },
      {
        exact: true,
        path: `${routes.EVENT}${routes.ID}${routes.LABELS}${routes.ID}`,
        component: lazy(() => import('src/views/event/LabelsEditView'))
      }
    ]
  },
  {
    exact: true,
    guard: AuthGuard,
    path: routes.EMAIL_VERIFIED,
    component: lazy(() => import('src/views/auth/EmailVerified'))
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: routes.VERIFY_EMAIL,
    component: lazy(() => import('src/views/auth/VerifyEmail'))
  },
  {
    exact: true,
    path: routes.NOT_FOUND,
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: routes.LOGIN,
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: routes.LOGOUT,
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: routes.REGISTER,
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: routes.THANK_YOU_PAGE,
    component: lazy(() => import('src/views/auth/ThankYouView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: routes.RESET_PASSWORD,
    component: lazy(() => import('src/views/auth/ResetPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: routes.RESET_PASSWORD_FORM,
    component: lazy(() => import('src/views/auth/ResetPasswordFormView'))
  },
  {
    exact: true,
    path: routes.STYLE_GUIDE,
    component: lazy(() => import('src/views/pages/StyleGuide/StyleGuideView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: routes.ZAPIER_LOGIN,
    component: lazy(() => import('src/views/auth/ZapierLoginView'))
  },
  {
    path: routes.PROFILE,
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: routes.PROFILE,
        component: lazy(() => import('src/views/profile/ProfileView'))
      },
      {
        exact: true,
        path: `${routes.PROFILE}${routes.EDIT}`,
        component: lazy(() => import('src/views/profile/ProfileEditView'))
      }
    ]
  },
  {
    component: lazy(() => import('src/views/pages/Error404View'))
  }
];

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<></>}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
