import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Container,
  Box,
  Divider,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import LinkTabs from 'src/components/LinkTabs/LinkTabs';
import * as routes from 'src/routeConstants';
import { translate } from 'src/translations/i18n';
import { useLocation } from 'react-router';
import BillingDetailsVerificationBar from 'src/layouts/TicketsLayout/BillingDetailsVerificationBar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

function BillingLayout({ children }) {
  const classes = useStyles();
  const { pathname: currentPath } = useLocation();

  return (
    <Page className={classes.root} title={translate('pageTitle.billing')}>
      <Container maxWidth="lg">
        <Typography variant="h3" color="textPrimary" className="pageTitleStyle">
          {translate(`billing.pageName`)}
        </Typography>
        <Box mt={3}>
          <LinkTabs
            currentPath={currentPath}
            paths={{
              [`${routes.BILLING}`]: {
                label: translate('billing.tabs.overview'),
                index: 0
              },
              [`${routes.BILLING}${routes.PACKAGES}`]: {
                label: 'Billing plans',
                index: 1
              },
              [`${routes.BILLING}${routes.CREDITS}`]: {
                label: 'Credits',
                index: 2
              },
              [`${routes.BILLING}${routes.INVOICES}`]: {
                label: 'Invoices',
                index: 3
              },
              [`${routes.BILLING}${routes.BILLING_INFORMATION}`]: {
                label: translate('billing.tabs.billingDetails'),
                index: 4
              }
            }}
          />
        </Box>
        <Divider />
        <BillingDetailsVerificationBar />
        {children}
      </Container>
    </Page>
  );
}

BillingLayout.propTypes = {
  children: PropTypes.any
};

export default BillingLayout;
