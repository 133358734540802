import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import authService from 'src/services/authService';
import eventService from 'src/services/eventService';
import domainService from 'src/services/domainService';
import sessionService from 'src/services/sessionService';
import teamService from 'src/services/teamService';
import { asyncForEach } from 'src/utils/asyncUtils';

export const CREATE_EVENT = '@event/create-event';
export const UPDATE_EVENT = '@event/update-event';
export const UPDATE_EVENT_PARTIAL = '@event/update-event-partial';
export const ARCHIVE_EVENT = '@event/archive-event';
export const DELETE_EVENT = '@event/delete-event';
export const SET_EVENT = '@event/set-event';
export const RESET_EVENTS = '@event/reset-events';
export const GET_EVENTS = '@event/get-events';
export const GET_SHARED_EVENTS = '@event/get-shared-events';
export const GET_ALL_EVENT_IDS_BY_USERS = '@event/get-all-events-by-user';
export const GET_USER_EVENTS_WITH_TICKETS =
  '@event/get-user-events-with-tickets';
export const SAVE_SLUG = '@event/save-slug';
export const VERIFY_SLUG = '@event/update-slug';
export const REMOVE_OUTLAW_SESSIONS = '@event/remove-outlaw-sessions';
export const INSTALL_GAME = '@event/install-game';

export const createEvent = createAsyncThunk(CREATE_EVENT, async event => {
  const newEvent = await eventService.createEvent(event);
  return newEvent;
});

export const updateEvent = createAsyncThunk(UPDATE_EVENT, async changes => {
  const change = await eventService.updateEvent(changes);
  return change;
});

export const updateEventHeader = createAsyncThunk(
  UPDATE_EVENT,
  async changes => {
    const change = await eventService.updateEventHeader(changes);
    return change;
  }
);

export const updateEventTicketBox = createAsyncThunk(
  UPDATE_EVENT,
  async changes => {
    const change = await eventService.updateEventTicketBox(changes);
    return change;
  }
);

export const updateEventPartial = createAsyncThunk(
  UPDATE_EVENT_PARTIAL,
  async changes => {
    const change = await eventService.updateEventPartial(changes);
    return change;
  }
);

export const saveSlug = createAsyncThunk(
  SAVE_SLUG,
  async ({ eventId, newSlug }) => {
    await eventService.saveSlug(eventId, newSlug);
    return newSlug;
  }
);

export const verifySlug = createAsyncThunk(VERIFY_SLUG, async ({ newSlug }) => {
  const valid = await eventService.verifySlug(newSlug);
  return valid;
});

export const archiveEvent = createAsyncThunk(ARCHIVE_EVENT, async eventId => {
  await eventService.archiveEvent(eventId);
  return eventId;
});

export const deleteEvent = createAsyncThunk(DELETE_EVENT, async eventId => {
  let domains = await domainService.getEventDomainsById(eventId);
  await asyncForEach(domains, async domain => {
    const accessToken = authService.getAccessToken();
    domainService.deleteDomain(domain, accessToken, eventId);
  });

  await eventService.deleteEvent(eventId);
  return eventId;
});

export const getEvents = createAsyncThunk(GET_EVENTS, async () => {
  const userId = authService.getUserUid();
  const events = await eventService.getUserEvents(userId);
  return events;
});

export const getSharedEvents = createAsyncThunk(GET_SHARED_EVENTS, async () => {
  const teams = await teamService.getTeamMemberByUserId();
  let events = [];
  if (teams.length !== 0) {
    await asyncForEach(teams, async team => {
      const userId = team.teamOwnerId;
      const userEvents = await eventService.getUserEvents(userId);
      events = events.concat(userEvents);
    });
  }
  return events;
});

export const getAllEventIdsByUsers = createAsyncThunk(
  GET_ALL_EVENT_IDS_BY_USERS,
  async () => {
    const userId = authService.getUserUid();
    const teams = await teamService.getTeamMemberByUserId();
    const teamOwnerUserIds =
      teams.length !== 0 ? teams.map(s => s.teamOwnerId) : [];
    const allUserIds = teamOwnerUserIds.concat([userId]);
    const eventIds = await eventService.getAllEventIdsByUsers(allUserIds);
    return eventIds;
  }
);

export const setEvent = createAsyncThunk(SET_EVENT, async eventId => {
  const event = await eventService.getEventById(eventId);
  const eventSlug = await eventService.getEventSlugById(eventId);
  return { event, eventSlug };
});

export const removeOutlawSessions = createAsyncThunk(
  REMOVE_OUTLAW_SESSIONS,
  async ({ outlawSessions }) => {
    if (outlawSessions.length) {
      const change = await sessionService.removeOutlawSessions(outlawSessions);
      return change;
    }
    return null;
  }
);

export const getUserEventsWithTickets = createAsyncThunk(
  GET_USER_EVENTS_WITH_TICKETS,
  async userId => {
    const data = await eventService.getUserEventsWithTickets(userId);
    return data;
  }
);

export const installGame = createAsyncThunk(INSTALL_GAME, async gameId => {
  const data = await eventService.installGame(gameId);
  return data;
});

export const resetEvents = createAction(RESET_EVENTS);
