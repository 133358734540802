import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import {
  archiveEvent,
  createEvent,
  getEvents,
  getSharedEvents,
  resetEvents,
  setEvent,
  updateEvent,
  updateEventPartial,
  saveSlug,
  deleteEvent,
  getUserEventsWithTickets,
  installGame
} from 'src/actions/eventActions';

export const eventEntityAdapter = createEntityAdapter({
  selectId: event => event.eventId
});

const initialState = eventEntityAdapter.getInitialState({
  loading: false,
  loadingInstallation: false,
  loaded: false,
  event: null,
  sharedEvents: [],
  eventsWithTickets: [],
  eventSlug: '',
  error: null
});

const eventReducer = createReducer(initialState, {
  [createEvent.pending]: (state, _) => {
    state.loading = true;
  },
  [createEvent.rejected]: (state, _) => {
    state.loading = false;
  },
  [createEvent.fulfilled]: (state, action) => {
    eventEntityAdapter.addOne(state, action.payload);
    state.event = action.payload;
    state.loading = false;
  },

  [getEvents.pending]: (state, _) => {
    state.loading = true;
  },
  [getEvents.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getEvents.fulfilled]: (state, action) => {
    state.loading = false;
    state.loaded = true;
    eventEntityAdapter.addMany(state, action.payload);
  },

  [getSharedEvents.pending]: (state, _) => {
    state.loading = true;
  },
  [getSharedEvents.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getSharedEvents.fulfilled]: (state, action) => {
    state.loading = false;
    state.loaded = true;
    state.sharedEvents = action.payload;
  },

  [archiveEvent.pending]: (state, _) => {
    state.loading = true;
  },
  [archiveEvent.rejected]: (state, _) => {
    state.loading = false;
  },
  [archiveEvent.fulfilled]: (state, action) => {
    eventEntityAdapter.removeOne(state, action.payload);
    state.loading = false;
  },
  [deleteEvent.pending]: (state, _) => {
    state.loading = true;
  },
  [deleteEvent.fulfilled]: (state, action) => {
    eventEntityAdapter.removeOne(state, action.payload);
    state.loading = false;
  },
  [deleteEvent.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateEvent.pending]: (state, _) => {
    state.loading = true;
  },
  [updateEvent.rejected]: (state, _) => {
    state.loading = false;
  },
  [updateEvent.fulfilled]: (state, action) => {
    state.event = { ...state.event, ...action.payload };
    state.loading = false;
    state.loaded = true;
  },
  [updateEventPartial.pending]: (state, _) => {
    state.loading = true;
  },
  [updateEventPartial.rejected]: (state, _) => {
    state.loading = false;
  },
  [updateEventPartial.fulfilled]: (state, { payload }) => {
    state.event = { ...state.event, ...payload.changes };
    state.loading = false;
    state.loaded = true;
  },
  [setEvent.pending]: (state, _) => {
    state.loading = true;
  },
  [setEvent.rejected]: (state, _) => {
    state.loading = false;
  },
  [setEvent.fulfilled]: (state, action) => {
    state.event = action.payload.event;
    state.eventSlug = action.payload.eventSlug;
    state.loaded = true;
    state.loading = false;
  },
  [saveSlug.pending]: (state, _) => {
    state.loading = true;
  },
  [saveSlug.rejected]: (state, payload) => {
    state.loading = false;
    state.error = payload;
  },
  [saveSlug.fulfilled]: (state, action) => {
    state.eventSlug = action.payload;
    state.loaded = true;
    state.loading = false;
  },
  [getUserEventsWithTickets.fulfilled]: (state, { payload }) => {
    state.eventsWithTickets = payload;
  },
  [resetEvents.type]: (_state, _) => {
    return initialState;
  },
  [installGame.pending]: (state, _) => {
    state.loadingInstallation = true;
  },
  [installGame.rejected]: (state, _) => {
    state.loadingInstallation = false;
  },
  [installGame.fulfilled]: (state, action) => {
    state.loadingInstallation = false;
    state.loaded = true;
  }
});

export default eventReducer;
