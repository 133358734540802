import {
  getCreditPackages,
  getCreditsPurchaseHistory,
  getEventConsumeReport,
  getUserCredits,
  purchaseCredits
} from 'src/core/api/CreditsStore';
import authService from 'src/services/authService';
import eventService from 'src/services/eventService';

class CreditsService {
  getCreditPackages = async () => {
    try {
      const creditPackages = await getCreditPackages();
      return creditPackages;
    } catch (error) {
      return error;
    }
  };

  getUserCredits = async eventId => {
    try {
      let userId = authService.getUserUid();
      let eventInfo = null;
      if (eventId) {
        eventInfo = await eventService.getEventById(eventId);
        if (eventInfo?.createdBy !== userId) {
          userId = eventInfo.createdBy;
        }
      }
      const result = await getUserCredits(userId);
      return result;
    } catch (error) {
      return error;
    }
  };

  getUserPurchaseHistory = async () => {
    try {
      let userId = authService.getUserUid();
      const result = await getCreditsPurchaseHistory(userId);
      return result;
    } catch (error) {
      return error;
    }
  };

  purchaseCredits = async creditsPackageId => {
    try {
      const userId = authService.getUserUid(creditsPackageId);
      const connectedAccountId = authService.getConnectedAccountId() || null;
      const result = await purchaseCredits(
        creditsPackageId,
        userId,
        connectedAccountId
      );

      return result;
    } catch (error) {
      return error;
    }
  };

  getEventConsumeReport = async eventId => {
    try {
      const result = await getEventConsumeReport(eventId);
      return result;
    } catch (error) {
      return error;
    }
  };
}

export default new CreditsService();
