import { createSelector } from '@reduxjs/toolkit';
import CUSTOMIZATION_DEFAULTS from 'src/constants/customization-constants';
import { eventEntityAdapter } from 'src/reducers/eventReducer';
import _ from 'lodash';

const selectEventState = state => state.event;

export const selectEventInfo = createSelector(
  selectEventState,
  state => state.event
);

export const selectEventCustomizations = createSelector(
  selectEventInfo,
  event =>
    event?.customization
      ? _.defaults({ ...event.customization }, CUSTOMIZATION_DEFAULTS) // handle when an old theme structure comes with the event.
      : CUSTOMIZATION_DEFAULTS
);

export const selectEventLoading = createSelector(
  selectEventState,
  state => state.loading
);

export const selectEventLoaded = createSelector(
  selectEventState,
  state => state.loaded
);

export const selectEventsList = eventEntityAdapter.getSelectors(
  selectEventState
).selectAll;

export const selectEventSlug = createSelector(
  selectEventState,
  state => state.eventSlug || state.event?.id
);

export const selectSharedEvents = createSelector(
  selectEventState,
  state => state.sharedEvents
);

export const selectEventsWithTickets = createSelector(
  selectEventState,
  state => state.eventsWithTickets
);

export const createdBySelector = createSelector(
  selectEventInfo,
  state => state?.createdBy || ''
);
export const selectGameIsInstalling = createSelector(
  selectEventState,
  state => state.loadingInstallation
);
