import { createSelector } from '@reduxjs/toolkit';
export const creditsState = state => state.credits;

export const selectCreditsPackages = createSelector(
  creditsState,
  state => state.creditsPackages
);

export const selectPurchaseHistory = createSelector(
  creditsState,
  state => state.creditsHistory
);
export const selectCreditsAmount = createSelector(
  creditsState,
  state => state.creditsAmount
);

export const selectCreditsLoading = createSelector(
  creditsState,
  state => state.loadingPurchase
);

export const selectCreditsPackagesLoading = createSelector(
  creditsState,
  state => state.loading
);

export const selectCreditsAmountLoading = createSelector(
  creditsState,
  state => state.loading
);

export const selectCreditsConsumeReport = createSelector(
  creditsState,
  state => state.consumeReport
);

export const selectLoadingConsumeReport = createSelector(
  creditsState,
  state => state.loadingConsumeReport
);
