import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  appId: process.env.REACT_APP_APP_ID
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.firestore().settings({ experimentalAutoDetectLongPolling: true });
}

export const auth = firebase.auth();
export const authProviders = {
  google: {
    id: 'google_provider',
    provider: new firebase.auth.GoogleAuthProvider().setCustomParameters({
      prompt: 'select_account'
    })
  }
};
export const db = firebase.database();
export const store = firebase.firestore();
export const storage = firebase.storage();
export const functions = firebase.app().functions('europe-west3');
// functions.useFunctionsEmulator('http://localhost:5001'); // for local development only
// store.useEmulator('localhost', 8080);
