import {
  createNetworks,
  updateNetwork,
  updateNetworksSort,
  deleteNetworks,
  getNetworkById,
  getNetworks,
  resetNetworks
} from 'src/actions/networkActions';
import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';

export const networkEntityAdapter = createEntityAdapter({
  sortComparer: (a, b) =>
    a.hasOwnProperty('sort') && b.hasOwnProperty('sort')
      ? a.sort - b.sort
      : a.createdDate?.toDate() - b.createdDate?.toDate()
});

const initialState = networkEntityAdapter.getInitialState({
  loading: false,
  loadingDeletingNetwork: false,
  loaded: false,
  error: null
});

const networkReducer = createReducer(initialState, {
  [createNetworks.pending]: (state, _) => {
    state.loading = true;
  },
  [createNetworks.fulfilled]: (state, { payload }) => {
    state.loading = false;
    networkEntityAdapter.upsertMany(state, payload);
  },
  [createNetworks.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateNetwork.pending]: (state, _) => {
    state.loading = true;
  },
  [updateNetwork.fulfilled]: (state, { payload }) => {
    state.loading = false;
    networkEntityAdapter.updateOne(state, payload);
  },
  [updateNetwork.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateNetworksSort.pending]: (state, _) => {
    state.loading = true;
  },
  [updateNetworksSort.fulfilled]: (state, { payload }) => {
    state.loading = false;
    networkEntityAdapter.upsertMany(state, payload);
  },
  [updateNetworksSort.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [deleteNetworks.pending]: (state, _) => {
    state.loadingDeletingNetwork = true;
  },
  [deleteNetworks.fulfilled]: (state, { payload }) => {
    state.loadingDeletingNetwork = false;
    networkEntityAdapter.removeMany(state, payload);
  },
  [deleteNetworks.rejected]: (state, { error }) => {
    state.loadingDeletingNetwork = false;
    state.error = error;
  },
  [getNetworkById.pending]: (state, _) => {
    state.loading = true;
  },
  [getNetworkById.fulfilled]: (state, { payload }) => {
    state.loading = false;
    networkEntityAdapter.upsertOne(state, payload);
  },
  [getNetworkById.rejected]: (state, { error }) => {
    state.error = error;
    state.loading = false;
  },
  [getNetworks.pending]: (state, _) => {
    state.loading = true;
  },
  [getNetworks.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    networkEntityAdapter.upsertMany(state, payload);
  },
  [getNetworks.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [resetNetworks.type]: (_, __) => {
    return initialState;
  }
});

export default networkReducer;
