import {
  AppBar,
  Box,
  makeStyles,
  Toolbar,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import Logo from 'src/components/LightLogo';
import { THEMES } from 'src/constants';
import * as routes from 'src/routeConstants';
import { translate } from 'src/translations/i18n';
import Account from './Account';
import BillingNotificationBubble from 'src/views/event/components/BillingNotificationBubble';
import Notifications from './Notifications';
import { selectCreditsAmount } from 'src/selectors/creditsSelector';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.background.main
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.main
        }
      : {}),
    overflow: 'auto'
  },
  toolbar: {
    minHeight: 64
  },
  menuLink: {
    color: theme.palette.background.default,
    textDecoration: 'none'
  },
  emailNotVerified: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

function TopBar({ className, onMobileNavOpen, ...rest }) {
  const classes = useStyles();
  const account = useSelector(state => state.account);
  const creditsAmount = useSelector(selectCreditsAmount);
  const accountVerified = account.user !== null && account.user.email_verified;
  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar
        className={clsx(
          classes.toolbar,
          !accountVerified ? classes.emailNotVerified : ''
        )}
      >
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        {accountVerified && (
          <>
            <Box ml={2} flexGrow={1} />
            <Box ml={2} flexGrow={1}>
              <RouterLink to={routes.EVENTS} className={classes.menuLink}>
                <Typography variant="h6" color="inherit">
                  {translate('topBar.events')}
                </Typography>
              </RouterLink>
            </Box>
            <Box ml={2} flexGrow={1}>
              <RouterLink to={routes.BILLING} className={classes.menuLink}>
                <Box display="flex" alignItems="center">
                  <Typography variant="h6" color="inherit">
                    {translate('topBar.billing')}
                  </Typography>
                  <Box mx={1}>
                    <BillingNotificationBubble />
                  </Box>
                </Box>
              </RouterLink>
            </Box>
            <Box ml={2} flexGrow={1}>
              <RouterLink to={routes.ATTENDEES} className={classes.menuLink}>
                <Typography variant="h6" color="inherit">
                  {translate('topBar.attendees')}
                </Typography>
              </RouterLink>
            </Box>
            <Box ml={2} flexGrow={1}>
              <RouterLink
                to={`${routes.BILLING}${routes.CREDITS}`}
                className={classes.menuLink}
              >
                <Typography variant="h6" color="inherit">
                  {creditsAmount} {translate('credits.credits')}
                </Typography>
              </RouterLink>
            </Box>
            <Notifications />
          </>
        )}
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
