export const HOME = '/';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const RESET_PASSWORD = '/reset-password';
export const RESET_PASSWORD_FORM = '/reset';
export const EMAIL_VERIFIED = '/email-verified';
export const VERIFY_EMAIL = '/verify-email';
export const LOGOUT = '/logout';
export const NOT_FOUND = '/404';
export const STYLE_GUIDE = '/style-guide';
export const ZAPIER_LOGIN = '/zapier-login';

export const CREATE = '/create';
export const REPORTS = '/reports';
export const EDIT = '/edit';
export const ID = '/:id';
export const SECTION = '/:section';
export const ATTACH = '/attach';
export const CUSTOMIZE = '/customTrackingCode';
export const ADD_BILLING_INFORMATION = '/addBillingInformation';
export const VIDEO_ID = '/:videoId';

export const EVENT = '/event';
export const EVENTS = '/events';
export const SHARED = '/shared';
export const KEY_DETAILS = '/details';
export const SPEAKERS = '/speakers';
export const STAGES = '/stages';
export const SCHEDULE = '/schedule';
export const TICKETS = '/tickets';
export const VIDEO = '/video';
export const SPONSORS = '/sponsors';
export const CATEGORY = '/category';
export const BOOTH = '/booth';
export const NETWORKING = '/networking';
export const THEME = '/theme';
export const THEME_SETTINGS = '/theme-settings';
export const DOMAIN = '/domain';
export const INTEGRATIONS = '/integrations';
export const TRACKING_CODES = '/tracking-codes';
export const TRANSLATIONS = '/translations';
export const SETTINGS = '/settings';
export const BILLING = '/billing';
export const BILLING_INFORMATION = '/details';
export const ATTENDEES = '/attendees';
export const TEAM = '/team';
export const REQUESTS = '/requests';
export const PROMO_CODES = '/promo-codes';
export const ADD_ONS = '/add-ons';
export const LANDING_PAGE = '/landing-page';
export const TESTIMONIALS = '/testimonials';
export const CHECKLIST = '/checklist';
export const BENEFITS = '/benefits';
export const COLORS_AND_FONTS = '/colors-and-fonts';
export const LEGAL_INFORMATION = '/legal-information';
export const TERMS_CONDITIONS = '/terms-and-conditions';
export const PRIVACY_POLICY = '/privacy-policy';
export const CUSTOM_SECTIONS = '/custom-sections';
export const REGISTRATION_FORM = '/customize-register-form';
export const LIVE_VIDEO_SETTINGS_FORM = '/customize-live-video-settings';
export const CHAT_SETTINGS_FORM = '/customize-chat-settings';
export const APP_STORE = '/app-store';
export const CONQUEST_OF_EVEREST = '/conquestOfEverest';
export const CUSTOM_REGISTRATION_FORMS = '/custom-registration-forms';
export const NEW_CUSTOM_REGISTRATION_FORMS = '/new-custom-registration-forms';
export const LABELS = '/labels';
export const PROFILE = '/profile';
export const EDIT_PROFILE = '/edit-profile';
export const PACKAGES = '/plan';
export const CREDITS = '/credits';
export const CHECKOUT = '/checkout';
export const CONFIRMATION = '/confirmation';
export const INVOICES = '/invoices';
export const EMAIL_TEMPLATES = '/email-templates';
export const SCAN_QR = '/scan-qr';
export const REPORT_DOWNLOADS = '/report-downloads';
export const REPORTS_SESSIONS = 'reports-sessions';
export const REPORTS_NETWORKING = 'reports-networking';
export const REPORTS_EXPO = 'reports-expo';
export const REPORTS_GAME = '/reports-game';
export const THANK_YOU_PAGE = '/thank-you';
